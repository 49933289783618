import * as React from 'react'

type Props = {
  children: React.ReactNode
  onClick: () => void
}

const buttonClasses =
  'w-[330px] md:w-[367px] text-xl md:text-2xl text-white h-[58px] bg-button-purple rounded-full border-none btn-animation'

export const HeroButton = ({ children, onClick }: Props) => (
  <button className={buttonClasses} onClick={onClick}>
    {children}
  </button>
)
