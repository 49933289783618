import * as React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import * as styles from './Card.module.scss'

type Props = {
  backgroundImage: IGatsbyImageData
  description: string
  title: string
}

export const Card = ({ backgroundImage, description, title }: Props) => (
  <div className={`${styles.Container} rounded-3xl`}>
    <GatsbyImage alt="" className="w-full" image={backgroundImage} />
    <div className={styles.Overlay} />
    <div className="flex flex-col justify-center h-full absolute ml-10">
      <h1 className="text-white text-5xl mb-5">{title}</h1>
      <p className="text-white w-full md:w-9/12 text-2xl">{description}</p>
    </div>
  </div>
)
