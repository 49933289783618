import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

type Card = {
  backgroundImage: {
    localFile: IGatsbyImageData
  }
  description: string
  title: string
}

type CardQueryResponse = {
  wpPage: {
    ACF_HomePage: {
      card1: Card
      card2: Card
      card3: Card
      brandedCard: {
        title: string
        description: string
        buttonTitle: string
        pageLink: {
          id: string
          uri: string
        }
      }
    }
    id: string
  }
}

export const useCardQuery = () => {
  const data = useStaticQuery<CardQueryResponse>(graphql`
    fragment cardBackground on WpMediaItem {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: BLURRED)
        }
      }
    }

    query CardQuery {
      wpPage(slug: { eq: "home" }) {
        id
        ACF_HomePage {
          card1 {
            title
            description
            backgroundImage {
              ...cardBackground
            }
          }
          card2 {
            title
            description
            backgroundImage {
              ...cardBackground
            }
          }
          card3 {
            title
            description
            backgroundImage {
              ...cardBackground
            }
          }
          brandedCard {
            buttonTitle
            description
            pageLink {
              ... on WpPage {
                id
                uri
              }
            }
            title
          }
        }
      }
    }
  `)

  return data
}
