import { graphql, useStaticQuery } from 'gatsby'

type VideoQueryResponse = {
  wpPage: {
    ACF_HomePage: {
      videoUrl: string
    }
  }
}

export const useVideoQuery = () => {
  const data = useStaticQuery<VideoQueryResponse>(graphql`
    query VideoQuery {
      wpPage(slug: { eq: "home" }) {
        id
        ACF_HomePage {
          videoUrl
        }
      }
    }
  `)

  return data
}
