import * as React from 'react'

import { Cards, Customers, Hero, InfoBoxes, ReturnCenterVideo } from '../features/LandingPage'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

const LandingPage = () => (
  <Layout>
    <SEO title="ReturnCenter" />
    <main>
      <Hero />
      <ReturnCenterVideo />
      <InfoBoxes />
      <Cards />
      <Customers />
    </main>
  </Layout>
)

export default LandingPage
