import { graphql, useStaticQuery } from 'gatsby'

type Box = {
  buttonTitle: string
  description: string
  title: string
  videoUrl: string
}

type CardQueryResponse = {
  wpPage: {
    ACF_HomePage: {
      box1: Box
      box2: Box
      box3: Box
    }
    id: string
  }
}

export const useInfoBoxQuery = () => {
  const data = useStaticQuery<CardQueryResponse>(graphql`
    query InfoBoxQuery {
      wpPage(slug: { eq: "home" }) {
        id
        ACF_HomePage {
          box1 {
            title
            description
            buttonTitle
            videoUrl
          }
          box2 {
            title
            description
            buttonTitle
            videoUrl
          }
          box3 {
            title
            description
            buttonTitle
            videoUrl
          }
        }
      }
    }
  `)

  return data
}
