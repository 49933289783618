import * as React from 'react'
import { getImage } from 'gatsby-plugin-image'

import { AnimationWrapper } from '../../../components/AnimationWrapper'
import { Card } from '../../../components/Card'

import { useCardQuery } from './useCardQuery'
import { BrandedCard } from '../../../components/BrandedCard'

export const Cards = () => {
  const {
    wpPage: {
      ACF_HomePage: { card1, card2, card3, brandedCard },
    },
  } = useCardQuery()

  return (
    <div>
      <div className="flex flex-col md:w-9/12 2xl:w-7/12 mx-auto mt-[60px] space-y-10 mb-10 px-10 md:px-0">
        <AnimationWrapper>
          <Card
            backgroundImage={getImage(card1.backgroundImage.localFile)!}
            description={card1.description}
            title={card1.title}
          />
        </AnimationWrapper>

        <AnimationWrapper>
          <Card
            backgroundImage={getImage(card2.backgroundImage.localFile)!}
            description={card2.description}
            title={card2.title}
          />
        </AnimationWrapper>

        <AnimationWrapper>
          <Card
            backgroundImage={getImage(card3.backgroundImage.localFile)!}
            description={card3.description}
            title={card3.title}
          />
        </AnimationWrapper>

        <AnimationWrapper>
          <div className="mx-auto">
            <BrandedCard
              description={brandedCard.description}
              title={brandedCard.title}
              buttonTitle={brandedCard.buttonTitle}
              buttonUrl={brandedCard.pageLink.uri}
            />
          </div>
        </AnimationWrapper>
      </div>
    </div>
  )
}
