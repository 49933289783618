import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { AnimationWrapper } from '../../../components/AnimationWrapper'
import { HeroButton } from './HeroButton'
import { SecondaryHeroButton } from './SecondaryHeroButton'
import TruckIcon from '../../../images/Truck.svg'
import BoxIconReturns from '../../../images/BoxIconReturns.svg'
import CreateAccountIcon from '../../../images/CreateAccount.svg'
import { useHeroQuery } from './useHeroQuery'

import * as styles from './Hero.module.scss'

export const Hero = () => {
  const {
    wpPage: { ACF_HomePage: page },
  } = useHeroQuery()
  return (
    <div className={styles.HeroContainer}>
      <GatsbyImage
        className="absolute top-[42px] h-[375px] lg:h-full max-h-[529px] w-full object-fill mb-10"
        alt="Hero Image"
        image={getImage(page.heroImage.localFile)!}
        style={{position:'absolute'}}
      />
      <div className="relative top-0 w-full flex flex-col items-center w-full">
        <AnimationWrapper>
          <h1 className="text-5xl md:text-6xl text-white text-center">
            {page.heroTitle}
          </h1>
          <div className={styles.HeroButtons}>
            <HeroButton
              onClick={() =>
                window.open(process.env.GATSBY_EMPLOYEE_RETURNS_URL, '_blank')
              }
            >
              <div className="relative flex justify-center items-center h-full">
                <div className="absolute left-8">
                  <BoxIconReturns />
                </div>
                <div className="ml-8">Remote Employee Returns</div>
              </div>
            </HeroButton>
          </div>
          <div className={styles.HeroButtons_secondary}>
            <SecondaryHeroButton
              onClick={() =>
                window.open(process.env.GATSBY_NEW_RETURN_URL, '_blank')
              }
            >
              <div className="relative flex justify-center items-center h-full">
                <div className="ml-0">Copier Shipments</div>
              </div>
            </SecondaryHeroButton>
            <SecondaryHeroButton
              onClick={() =>
                window.open(process.env.GATSBY_IT_SHIPMENTS_URL, '_blank')
              }
            >
              <div className="relative flex justify-center items-center h-full">
                <div className="ml-0">IT Asset Shipments</div>
              </div>
            </SecondaryHeroButton>
          </div>
        </AnimationWrapper>
      </div>
    </div>
  )
}
