import React from 'react'

import { useVideoQuery } from './useVideoQuery'

export const ReturnCenterVideo = () => {
  const {
    wpPage: {
      ACF_HomePage: { videoUrl },
    },
  } = useVideoQuery()

  return (
    <div className="px-2 md:px-0 w-full md:w-9/12 2xl:w-7/12 mx-auto mt-[64px] mb-[68px]">
      <div className="pt-[56.25%] w-full overflow-hidden relative rounded-xl">
        <iframe
          aria-label="ReturnCenter How It Works video"
          className="w-full h-full absolute top-0 right-0 left-0 bottom-0"
          src={videoUrl}
          title="ReturnCenter_Main_8.mp4"
        />
      </div>
    </div>
  )
}
