import { graphql, useStaticQuery } from 'gatsby'
import { ImageDataLike } from 'gatsby-plugin-image'

type HeroQueryResponse = {
  wpPage: {
    id: string
    ACF_HomePage: {
      heroTitle: string
      heroImage: {
        localFile: ImageDataLike
      }
    }
  }
}

export const useHeroQuery = () => {
  const data = useStaticQuery<HeroQueryResponse>(graphql`
    query HeroQuery {
      wpPage(slug: { eq: "home" }) {
        id
        ACF_HomePage {
          heroTitle
          heroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  return data
}
