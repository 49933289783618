import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image'

type Logo = {
  localFile: {
    publicURL: string
  }
}

type LogoQueryResponse = {
  wpPage: {
    id: string
    ACF_HomePage: {
      customers: {
        title: string
        logos?: {
          logo: Logo
        }[]
      }
    }
  }
}

export const useCustomerQuery = () => {
  const data = useStaticQuery<LogoQueryResponse>(graphql`
    query CustomerQuery {
      wpPage(slug: { eq: "home" }) {
        id
        ACF_HomePage {
          customers {
            title
            logos {
              logo {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return data
}
