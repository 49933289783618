import * as React from 'react'

import * as styles from './InfoBox.module.scss'

type Props = {
  buttonTitle: string
  description: string
  icon: React.ReactNode
  title: string
  videoUrl: string
}

export const InfoBox = ({
  buttonTitle,
  description,
  icon,
  title,
  videoUrl,
}: Props) => (
  <div className={styles.Container}>
    <div className="flex flex-col items-center">
      <h2
        className="text-white p-8"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className="py-3">{icon}</div>
      <span
        className="text-white p-3 leading-8"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    <div className="btn-animation my-5 md:my-0 md:mb-[35px] w-full">
      <a
        className="rounded-full border-stone-200 border-2 mx-auto text-xl block text-center leading-[46px] h-[46px] px-10 w-11/12 text-white "
        href={videoUrl}
      >
        {buttonTitle}
      </a>
    </div>
  </div>
)
