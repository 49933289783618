import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
  buttonTitle: string
  buttonUrl: string
  description: string
  title: string
}

const imageStyle = 'w-auto h-auto object-fit'

export const BrandedCard = ({
  buttonTitle,
  buttonUrl,
  description,
  title,
}: Props) => (
  <div className="rounded-3xl text-center mt-[116px] bg-gradient-to-b from-button-purple p-10">
    <h1
      className="text-white text-5xl md:text-6xl my-5 w-9/12 md:w-6/12 mx-auto"
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <div className="my-20 w-11/12 md:w-8/12 mx-auto">
      <span
        className="text-white text-2xl text-left"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>

    <div className="my-20 flex justify-center space-x-10 md:space-x-[126px] w-50">
      <StaticImage
        className={imageStyle}
        alt="business-icon"
        src="../../images/store-front.png"
      />
      <StaticImage
        className={imageStyle}
        alt="business-icon"
        src="../../images/business.png"
      />
      <StaticImage
        className={imageStyle}
        alt="business-icon"
        src="../../images/housing.png"
      />
    </div>

    <div className="mt-[68px] flex justify-center">
      <Link to={buttonUrl} className="btn-animation">
        <div className="border-2 border-stone-200 flex items-center justify-center rounded-full text-2xl h-[56px] w-[300px]">
          {buttonTitle}
        </div>
      </Link>
    </div>
  </div>
)
