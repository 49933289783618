import * as React from 'react'

import { AnimationWrapper } from '../../../components/AnimationWrapper'
import { InfoBox } from '../../../components/InfoBox'

import { useInfoBoxQuery } from './useInfoBoxQuery'

import AssetDisposition from '../../../images/AssetDispositionRecycling.svg'
import EmployeeAssetReturn from '../../../images/EmployeeAssetReturn.svg'
import LeaseEquipment from '../../../images/LeaseEquipment.svg'

export const InfoBoxes = () => {
  const {
    wpPage: {
      ACF_HomePage: { box1, box2, box3 },
    },
  } = useInfoBoxQuery()

  return (
    <div className="px-10 md:px-0 w-full md:w-9/12 2xl:w-7/12 md:mx-auto mb-10 grid grid-cols-1 lg:grid-cols-3 gap-5">
      <AnimationWrapper>
        <InfoBox
          title={box1.title}
          description={box1.description}
          icon={<LeaseEquipment />}
          buttonTitle={box1.buttonTitle}
          videoUrl={box1.videoUrl}
        />
      </AnimationWrapper>
      <AnimationWrapper>
        <InfoBox
          title={box2.title}
          description={box2.description}
          icon={<EmployeeAssetReturn />}
          buttonTitle={box2.buttonTitle}
          videoUrl={box2.videoUrl}
        />
      </AnimationWrapper>
      <AnimationWrapper>
        <InfoBox
          title={box3.title}
          description={box3.description}
          icon={<AssetDisposition />}
          buttonTitle={box3.buttonTitle}
          videoUrl={box3.videoUrl}
        />
      </AnimationWrapper>
    </div>
  )
}
