import * as React from 'react'

type Props = {
  children: React.ReactNode
  onClick: () => void
}

const buttonClasses =
  'w-[330px] md:w-[367px] text-xl md:text-2xl text-white h-[58px] bg-black bg-button-black rounded-full border border-solid border-white btn-animation ml-0 mr-0'

export const SecondaryHeroButton = ({ children, onClick }: Props) => (
  <button className={buttonClasses} onClick={onClick}>
    {children}
  </button>
)
