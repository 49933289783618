import * as React from 'react'

type Props = {
  animationType?: 'slide-up' | 'slide-down' | 'fade'
  children: React.ReactNode
}

export const AnimationWrapper = ({
  animationType = 'slide-up',
  children,
}: Props) => (
  <div
    data-sal={animationType}
    data-sal-delay="300"
    data-sal-duration="800"
    data-sal-easing="ease"
  >
    {children}
  </div>
)
