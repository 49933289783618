import * as React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { AnimationWrapper } from '../../../components/AnimationWrapper'
import { useCustomerQuery } from './useCustomersQuery'

import ArrowIcon from '../../../images/ArrowFocused.svg'

import * as styles from './Customers.module.scss'

const responsive = {
  xLargeMonitor: {
    breakpoint: { max: 7680, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CustomLeftArrow = ({ onClick }: any) => (
  <button
    className={`${styles.Arrow} react-multiple-carousel__arrow react-multiple-carousel__arrow--left cursor-pointer`}
    onClick={onClick}
  >
    <ArrowIcon />
  </button>
)

const CustomRightArrow = ({ onClick }: any) => (
  <button
    className={`${styles.Arrow} react-multiple-carousel__arrow react-multiple-carousel__arrow--right cursor-pointer rotate-180`}
    onClick={onClick}
  >
    <ArrowIcon />
  </button>
)

export const Customers = () => {
  const {
    wpPage: {
      ACF_HomePage: {
        customers: { title, logos },
      },
    },
  } = useCustomerQuery()

  return (
    <div className="md:w-9/12 my-28 mx-auto">
      <AnimationWrapper>
        <h1 className="text-center text-5xl px-2">{title}</h1>
      </AnimationWrapper>
      <div className="my-28">
        <Carousel
          autoPlay={false}
          autoPlaySpeed={0}
          centerMode={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          customTransition="all .25s"
          draggable={false}
          infinite={true}
          itemClass={`${styles.CarouselItem} self-center`}
          keyBoardControl={false}
          renderButtonGroupOutside={false}
          responsive={responsive}
          shouldResetAutoplay={false}
          transitionDuration={0}
        >
          {logos?.map((logo, index) => (
            <img
              key={index}
              alt=""
              className="w-40 mx-0"
              src={logo.logo.localFile.publicURL}
            />
          ))}
        </Carousel>
      </div>
    </div>
  )
}
